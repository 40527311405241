
import { Component, Vue } from 'vue-property-decorator'
import { dayFormat } from '@/utils/formatDate'
import echarts from 'echarts'
import { Device } from '../../types/insectMonitor'

@Component
export default class Xxx extends Vue {
  private date: string[] = []
  private dataList: Array<Device> = []
  private loading = false
  page = 1
  size = 10
  total = 0
  private columnList: Array<string> = []

  get deviceId () {
    return this.$route.params.deviceId as string
  }

  created () {
    this.getDetail()
    this.getDate()
  }

  // 获取七天前时间
  getDate () {
    const date = new Date()
    const time = date.getTime()
    const beforeMs = time - 3600 * 1000 * 24 * 6
    const beforeDate = date.setTime(beforeMs)
    this.date = [dayFormat(beforeDate), dayFormat(date.setTime(time))]
    this.onSearch()
  }

  getData () {
    const info = {
      deviceId: this.deviceId,
      startTime: this.date[0],
      endTime: this.date[1]
    }
    this.$axios.get(this.$apis.insectMonitor.selectInsectInfoLineChart, info).then((res) => {
      this.drawEchart(res.at, res.ah, res.collectTime)
    })
  }

  getListData () {
    this.loading = true
    const info = {
      deviceId: this.deviceId,
      startTime: this.date[0],
      endTime: this.date[1]
    }
    this.$axios.get<{ total: number; list: Array<Device> }>(this.$apis.insectMonitor.selectInsectInfoPage, {
      ...info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.insectMonitor.selectInsectConfigByList, {
      deviceId: this.deviceId
    }).then(res => {
      if (res.total > 0) {
        res.list.forEach((item: any) => {
          if (item.type === '1') {
            for (let i = 0; i < 9; i++) {
              if (i === (Number(item.typeCode) - 1)) {
                this.columnList[i] = '1'
              }
            }
          }
        })
      } else {
        for (let i = 0; i < 9; i++) {
          this.columnList[i] = '1'
        }
      }
    })
  }

  drawEchart (at: string[], ah: string[], date: string[]) {
    const Echarts: any = echarts.init(this.$refs.echarts as HTMLCanvasElement)
    const option = {
      title: {
        text: '温度湿度统计'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { // 坐标轴指示器，坐标轴触发有效
          type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      legend: {
        type: 'plain',
        left: 25,
        top: 40,
        icon: 'rect',
        itemHeight: 2,
        itemWidth: 12,
        itemGap: 16,
        textStyle: {
          color: 'rgba(140, 140, 140, 1)'
        }
      },
      grid: {
        bottom: 30,
        top: 80,
        left: 55,
        right: 30
      },
      color: ['#2C8EFFFF', '#FFC117FF'],
      xAxis: [
        {
          type: 'category',
          data: date,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#00000073'
            }
          },
          axisLabel: {
            margin: 15
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#00000026'
            }
          },
          axisLabel: {
            color: '#00000073'
          }
        }
      ],
      series: [
        {
          name: '温度',
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: '#2C8EFF33',
            shadowOffsetY: 20
          },
          data: at
        },
        {
          name: '湿度',
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: '#FFC11733',
            shadowOffsetY: 20
          },
          data: ah
        }
      ]
    }
    Echarts.setOption(option)
    window.onresize = function () {
      Echarts.resize()
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
    this.getListData()
  }
}
